(<template>
  <div class="ent-settings-table__header">
    <p v-for="col in colsList"
       :key="col.key"
       :class="`ent-settings-table__col--${col.key}`"
       class="ent-settings-table__col"
       v-html="col.name"></p>
    <p v-if="canSetDefault"
       class="ent-settings-table__col ent-settings-table__col--default-actions"></p>
    <p v-if="canEdit"
       class="ent-settings-table__col ent-settings-table__col--edit-actions"></p>
    <p v-if="canDelete"
       class="ent-settings-table__col ent-settings-table__col--delete-actions"></p>
  </div>
</template>)

<script>
  export default {
    props: {
      itemsList: {
        type: Array,
        default: () => []
      },
      colsList: {
        type: Array,
        default: () => []
      },
      canEdit: Boolean,
      canDelete: Boolean,
      canSetDefault: Boolean
    }
  };
</script>

<style scoped src="@assets/css/enterprise_setting_table.css"></style>
