(<template>
  <section :class="{'in-progress': progress}"
           class="ent-settings-table is-progress-bar">
    <ent-settings-table-header :can-delete="canChange"
                               :can-edit="canChange"
                               :can-set-default="canSetDefault"
                               :cols-list="desktopColsList" />
    <div v-for="(item, index) in itemsList"
         :key="index"
         :class="{'ent-settings-table__row--archived' : item.archived}"
         class="ent-settings-table__row">
      <ent-settings-table-row :item="item"
                              :cols-list="desktopColsList"
                              :style="{display: 'none'}"
                              class="ent-settings-table__table-row ent-settings-table__table-row--desktop" />
      <ent-settings-table-row :item="item"
                              :cols-list="mobileColsList"
                              :style="{display: 'none'}"
                              class="ent-settings-table__table-row ent-settings-table__table-row--mobile" />
      <div v-if="canSetDefault"
           class="ent-settings-table__col ent-settings-table__col--default-actions">
        <p v-if="item.archived"
           class="ent-settings-table__btn ent-settings-table__btn--archived">{{ $gettext('Archived') }}</p>
        <button v-else
                class="sk-btn sk-link sk-btn--like-link ent-settings-table__btn ent-settings-table__btn--default"
                @click="setItemAsDefault(item.id)">{{ $gettext('Set as default') }}</button>
      </div>
      <div class="ent-settings-table__col ent-settings-table__col--edit-actions">
        <button class="sk-btn sk-link sk-btn--like-link ent-settings-table__btn ent-settings-table__btn--edit"
                @click="editItem(item)">{{ $gettext('Edit') }}</button>
      </div>
      <div class="ent-settings-table__col ent-settings-table__col--delete-actions">
        <button class="ent-settings-table__btn ent-settings-table__btn--remove"
                @click="deleteItem(item.id)"></button>
      </div>
    </div>
    <section v-if="pageTotal"
             class="ent-settings__pagination-cont">
      <items-amount class="ent-settings__items-per-page"
                    @amountchanged="amountChanged" />
      <sk-pagination v-if="pageTotal && pageTotal > 1"
                     :current="selectedPage"
                     :total="pageTotal"
                     @prev="toPrevPage"
                     @next="toNextPage"
                     @newpage="toNewPage" />
    </section>
  </section>
</template>)

<script>
  import EnterpriseSettingTableRow from '@/components/enterprise/shared/EnterpriseSettingTableRow';
  import EnterpriseSettingTableHeader from '@/components/enterprise/shared/EnterpriseSettingTableHeader';
  import ItemsAmount from '@/components/shared_components/ItemsAmount';

  export default {
    components: {
      'ent-settings-table-header': EnterpriseSettingTableHeader,
      'ent-settings-table-row': EnterpriseSettingTableRow,
      'items-amount': ItemsAmount,
    },
    props: {
      progress: Boolean,
      canChange: Boolean,
      canSetDefault: Boolean,
      itemsList: {
        type: Array,
        default: () => []
      },
      colsList: {
        type: Array,
        default: () => []
      },
      currentPage: {
        type: Number,
        default: 1
      },
      pageTotal: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        selectedPage: this.currentPage
      };
    },
    computed: {
      desktopColsList() {
        return this.colsList.filter((col) => {
          return col.showOn === undefined ? true : col.showOn === 'desktop';
        });
      },
      mobileColsList() {
        return this.colsList.filter((col) => {
          return col.showOn === undefined ? true : col.showOn === 'mobile';
        });
      },
      params() {
        return {
          page: this.selectedPage
        };
      },
    },
    methods: {
      editItem(item) {
        this.$emit('edititem', item);
      },
      deleteItem(id) {
        this.$emit('deleteitem', id);
      },
      setItemAsDefault(id) {
        this.$emit('setitemasdefault', id);
      },
      amountChanged() {
        this.$emit('amountchanged');
      },
      toPrevPage() {
        this.selectedPage -= 1;
        this.$emit('submitfilters', this.params);
      },
      toNewPage(page) {
        this.selectedPage = +page;
        this.$emit('submitfilters', this.params);
      },
      toNextPage() {
        this.selectedPage += 1;
        this.$emit('submitfilters', this.params);
      },
    }
  };
</script>

<style scoped src="@assets/css/enterprise_setting_table.css"></style>

<style scoped>
.ent-settings__pagination-cont {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 5px 10px;
}

.ent-settings__items-per-page {
  margin-right: auto;
}
</style>
