(<template>
  <div class="ent-settings-table-row">
    <div v-for="(col, index) in colsList"
         :key="index"
         :class="[`ent-settings-table-row__col--${col.key}`]"
         class="ent-settings-table-row__col">
      <span class="ent-settings-table-row__col-title">{{ getColName(col.key) }}</span>
      <span class="ent-settings-table-row__col-content"
            v-html="getColValue(item, col.key)"></span>
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      item: {
        type: Object,
        default() {
          return {};
        }
      },
      colsList: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      getColName(key) {
        const col = this.colsList.find((col) => col.key === key) || {};
        return col.name;
      },
      getColValue(item, key) {
        if (Array.isArray(item[key]) && key === 'addresses') {
          return this.getAddress(item[key]).join('<br/>');
        }
        return item[key] || '-';
      },
      getAddress(addresses = []) {
        return addresses.map((address) => helpers.getFullAddress(address));
      }
    }
  };
</script>

<style scoped>
  .ent-settings-table-row {
    display: flex;
    width: 100%;
  }

  .ent-settings-table-row__col {
    width: 100%;
    padding-right: 5px;
    word-break: break-word;
  }

  .ent-settings-table-row__col-title {
    display: none;
    padding-right: 3px;
    font-weight: bold;
  }

  @media (max-width: 1024px) {
    .ent-settings-table-row {
      flex-wrap: wrap;
    }

    .ent-settings-table-row__col-title {
      display: inline;
    }
  }
</style>
